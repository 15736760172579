import { Feature } from "ol";
import { Coordinate } from "ol/coordinate";
import { Point } from "ol/geom";
import LayerGroup from "ol/layer/Group";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import { Circle as CircleStyle, Style } from "ol/style";

export class PointsLayer extends LayerGroup {

    private normalSource: VectorSource;
    private newSource: VectorSource;

    constructor(initialPoints: Coordinate[]) {

        let normalSource = new VectorSource({});
        let newSource = new VectorSource({});

        let pointsLayer = new VectorLayer({
            source: normalSource,
            style: new Style({
                image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({width: 1, color: "black"}),
                    fill: new Fill({color: "red"})
                })
            })
        });

        let newPointLayer = new VectorLayer({
            source: newSource,
            style: new Style({
                image: new CircleStyle({
                    radius: 7,
                    stroke: new Stroke({width: 1, color: "black"}),
                    fill: new Fill({color: "yellow"})
                })
            })
        })

        super({
            layers: [ pointsLayer, newPointLayer ]
        });

        this.normalSource = normalSource;
        this.newSource = newSource;

        this.addPointsToSource(initialPoints, normalSource);
    }

    private addPointsToSource(points: Coordinate[], source: VectorSource) {
        source.addFeatures(points.map((p, index) => {
            return new Feature({
                name: index.toString(),
                geometry: new Point(p),
                properties: {
                    pointIndex: index
                }
            });
        }));
    }

    public getNew(): Coordinate | null {
        if(this.newSource.isEmpty()) {
            return null;
        } else {
            let point = this.newSource.getFeatures()[0].getGeometry() as Point;
            return point.getCoordinates();
        }
    }

    public handleSelect(pointIndex: number) {
        // TODO
    }

    public handleClick(c: Coordinate) {
        this.clearNew();
        this.addPointsToSource([c], this.newSource);
    }

    public addPoint(c: Coordinate) {
        this.addPointsToSource([c], this.normalSource);
    }

    public clearNew() {
        this.newSource.clear();
    }
}

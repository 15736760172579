import {
    Link, useLoaderData, 
    useParams
  } from "react-router-dom";
import { GeorefImage } from "../model";
import { Button } from "@joushx/react-components";
import { StorageService } from "../services/StorageService";
import { ExpandingSection } from "../components/ExpandingSection";
import { generateGCPPointsFile, generateGdalScript, generateJson, generateKML, generateShareURL } from "../util";
import { Container } from "../components/Container";
import React, { useState } from "react";
import { ExifDataService } from "../services/ExifDataService";
import { toast } from "react-toastify";

export function ImageDetails() {
    const image = useLoaderData() as GeorefImage;
    const params = useParams();

    const [shareURL, setShareURL] = useState<string|null>(null);
    const [exifData, setExifData] = useState<any>(null);

    React.useEffect(() => {
        generateShareURL(image).then(shareURL => {
            setShareURL(shareURL);
        });

        ExifDataService.getData(image.url)
        .then(exif => setExifData(exif))
        .catch(e => console.warn("Unable to extract exid data: " + e));
    }, []);

    return <Container>
        <Link to={image.url} target="_blank">
            <img alt={image.id} src={image.url} className="object-scale-down max-h-96 w-full"/>
        </Link>

        <div className="mt-10">
            <ExpandingSection title="Metadata" initiallyExpanded={true}>
                <div>
                    <span className="font-semibold">Id: </span>
                    <span className="font-mono">{ image.id }</span>
                </div>
                <div>
                    <span className="font-semibold">URL: </span>
                    <span className="font-mono"><a href={image.url}>{ image.url.indexOf("data:") > -1 ? "(data url)" : image.url }</a></span>
                </div>
                <div>
                    <span className="font-semibold">Size: </span>
                    <span className="font-mono">{image.extent[2]}x{image.extent[3]}</span>
                </div>
            </ExpandingSection>

            { exifData &&
                <ExpandingSection title="EXIF">
                    <div>
                        <span className="font-semibold">Make: </span>
                        <span className="font-mono">{ exifData.Make }</span>
                    </div>
                    <div>
                        <span className="font-semibold">Model: </span>
                        <span className="font-mono">{ exifData.Model }</span>
                    </div>
                    <div>
                        <span className="font-semibold">Date: </span>
                        <span className="font-mono">{ exifData.DateTime }</span>
                    </div>
                    <div>
                        <span className="font-semibold">Focal Length: </span>
                        <span className="font-mono">{ exifData.FocalLength + "" }</span>
                    </div>
                </ExpandingSection>
            }

            { image.points.length > 0 && 
                <ExpandingSection title="Points">
                    <table className="table w-full md:text-base text-sm">
                        <thead>
                            <tr>
                                <th>Longitude</th>
                                <th>Latitude</th>
                                <th>Image X</th>
                                <th>Image Y</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { image.points.map((p,i) => {
                                return <tr key={i}>
                                    <td>{p.mapPoint[0]}</td>
                                    <td>{p.mapPoint[1]}</td>
                                    <td>{p.imagePoint[0]}</td>
                                    <td>{p.imagePoint[1]}</td>
                                    <td>
                                        <Button onClick={() => {
                                            image.points.splice(i, 1);
                                            StorageService.updateImage(params.project_id as string, image);
                                        }}>
                                            <i className="bi bi-x-circle-fill"></i>
                                        </Button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </ExpandingSection>
            }

            { image.points.length > 0 && 
                <ExpandingSection title="GDAL-Script">
                    <p>Download a bash script that uses gdal to generate a geotif from an image</p>

                    <Button onClick={() => generateGdalScript(image)}>Download</Button>
                </ExpandingSection>
            }

            { image.points.length > 0 && 
                <ExpandingSection title="Points file">
                    <p>Download a points file for use in qgis</p>

                    <Button onClick={() => generateGCPPointsFile(image)}>Download</Button>
                </ExpandingSection>
            }

            { image.points.length > 0 && 
                <ExpandingSection title="JSON-File">
                    <p>Download the image data as JSON for storage later re-import</p>

                    <Button onClick={() => generateJson(image)}>Download</Button>
                </ExpandingSection>
            }

            { image.points.length > 0 && shareURL && <ExpandingSection title="Share">
                <p>Share image as URL</p>

                <div className="flex">
                    <input type="text" disabled value={ shareURL } className="flex-1 rounded mr-2 px-2"/>
                    <Button onClick={() => {
                        navigator.clipboard.writeText(shareURL);
                        toast.info("Share URL copied to clipboard")
                    }}>copy</Button>
                </div>
            </ExpandingSection> }

            { image.points.length > 0 && 
                <ExpandingSection title="Photo Overlay KML">
                    <p>Download a KML to show 3D position in space</p>

                    <Button onClick={() => generateKML(image)}>Download</Button>
                </ExpandingSection>
            }
        </div>
    </Container>
}
import {
    Link, useLoaderData,
  } from "react-router-dom";
import { Project } from "../model";
import { Button } from "@joushx/react-components";
import { Container } from "../components/Container";
import { useState } from "react";
import { StorageService } from "../services/StorageService";

export function Images() {
    const project = useLoaderData() as Project;
    const [renaming, setRenaming] = useState(false);

    return <Container>
        <h1 className="text-3xl" onClick={() => setRenaming(true)} onBlur={(e) => { 
            project.name = e.target.innerText
            StorageService.updateProject(project)
        }} contentEditable={renaming}>{ project.name }</h1>

        <Link to={`/projects/${project.id}/map`}>Map</Link>
        <Link to={`/projects/${project.id}/3d`}>3D</Link>

        <div className="mt-5 grid grid-flow-row grid-cols-2 md:grid-cols-10 gap-2">
            {project.images.map(img => {
                return (
                    <Link to={`/projects/${project.id}/images/${img.id}`} className="aspect-square" key={img.id}>
                        <img alt={img.id} src={img.url} className="h-full w-full rounded object-cover"/>
                    </Link>
                );
            })}
        </div>

        <br/>

        <Link to={`/projects/${project.id}/images/new`}>
            <Button>Add image</Button>
        </Link>
    </Container>
}

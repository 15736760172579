import {
    Link, useLoaderData,
    useRevalidator,
  } from "react-router-dom";
import { Button } from "@joushx/react-components";
import { Container } from "../components/Container";
import { Project } from "../model";
import { StorageService } from "../services/StorageService";
import { v4 } from "uuid";
import { AuthService } from "../services/AuthService";

export function ProjectsView() {
    const projects = useLoaderData() as Project[];
    const revalidator = useRevalidator();

    return <Container>

        <h1 className="text-3xl">Local Projects</h1>

        <div className="mt-5 grid grid-flow-row grid-cols-2 md:grid-cols-10 gap-2">
            {projects.map((project) => {
                return (
                    <Link to={`/projects/${project.id}`} className="aspect-square" key={project.id}>
                        <div className="rounded-lg dark:bg-slate-700 p-2 h-full flex items-center justify-center">{project.name}</div>
                    </Link>
                );
            })}
        </div>

        <h1 className="text-3xl mt-10">Cloud Projects</h1>

        <div className="mt-5">
            <Button onClick={() => window.location.href = AuthService.getLoginURL()}>Log in with your jmittendorfer.at account</Button>
        </div>

        <div className="mt-10">
            <Button onClick={() => {
                let id = v4();
                StorageService.addProject({
                    id: id,
                    name: "New Project",
                    images: []
                });
                revalidator.revalidate();
            }}>Create Project</Button>
        </div>
    </Container>
}

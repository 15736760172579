import { useEffect } from 'react'
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import { get as getProjection } from 'ol/proj.js';
import Projection from 'ol/proj/Projection.js';
import React from 'react';
import { GpsMapCoordinate, ImageCoordinate, getDefaultLayers } from '../util';
import { GeorefLayer } from '../components/GeorefLayer';
import { GeorefImage } from '../model';
import { useLoaderData } from 'react-router-dom';
import LayerSwitcher from 'ol-layerswitcher';
import "ol-layerswitcher/dist/ol-layerswitcher.css";

export function CombinedViewer() {
    const mapDiv = React.useRef(null);
    const images = useLoaderData() as GeorefImage[];

    useEffect(() => {
        let mapProjection = getProjection('EPSG:3857');

        if(!mapDiv.current) {
            return;
        }

        let mainMap = new Map({
        layers: getDefaultLayers(),
        target: mapDiv.current,
        view: new View({
                projection: mapProjection as Projection,
                center: [0, 0],
                zoom: 5
            })
        });

        var layerSwitcher = new LayerSwitcher({
            tipLabel: 'Legende',
            groupSelectStyle: 'children'
        });
        mainMap.addControl(layerSwitcher);

        images.forEach(image => {
            let mapPoints = image.points.map(p => new GpsMapCoordinate(p.mapPoint[0], p.mapPoint[1]));
            let imagePoints = image.points.map(p => new ImageCoordinate(p.imagePoint[0], p.imagePoint[1]));

            let georefLayer = new GeorefLayer({
                id: image.id,
                url: image.url, 
                extent: image.extent
            });
            georefLayer.setPoints(mapPoints, imagePoints);
            mainMap.addLayer(georefLayer);
        })
    }, [images]);

    return <div className="md:flex h-full">
        <div className="w-96 p-2">
            <div className="grid grid-cols-4 md:grid-cols-4 gap-2">
                {images.map(img => {
                    return <img alt={img.id} src={img.url} className="cursor-pointer w-full rounded object-cover aspect-square"/>
                })}
            </div>
        </div>
        <div className="h-full flex-1" ref={mapDiv}></div>
    </div>
}

import { PropsWithChildren, useState } from "react";

export function ExpandingSection(props: ExpandingSectionProps) {
    const [expanded, setExpanded] = useState(props.initiallyExpanded);

    return <div>

        <div onClick={() => setExpanded(!expanded)} className="flex text-xl py-1 font-semibold cursor-pointer bg-neutral-600 hover:bg-neutral-500 px-2">
            <div className="flex-1">
                {props.title}
            </div>
            <div>
                { expanded ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}
            </div>
        </div>

        { expanded && <div className="p-2 w-full overflow-x-auto">
            { props.children}
        </div>}
    </div>
}

export interface ExpandingSectionProps extends PropsWithChildren {
    title: string;
    initiallyExpanded?: boolean;
}
import ImageLayer from "ol/layer/Image";
import { Projection, addCoordinateTransforms } from "ol/proj";
import Static from "ol/source/ImageStatic";
import { mapToImage, imageToMap, calculate } from "../services/GdalService";
import { OlMapCoordinate, ImageCoordinate, OlImageCoordinate, GpsMapCoordinate } from "../util";

export class GeorefLayer extends ImageLayer<Static> {

    private projection: Projection;

    constructor(private options: GeorefLayerOptions) {
        super({})

        console.log(options.extent);

        this.projection = new Projection({
            code: options.id,
            units: 'pixels',
            extent: options.extent
        });
      
        addCoordinateTransforms(
            'EPSG:3857',
            this.projection,
            coords => {
                // map to image
                let typedCoords = new OlMapCoordinate(coords[0], coords[1]);
                let result: ImageCoordinate = mapToImage(typedCoords.toGps());
                return result.flip(options.extent[3]).toArray();
            },
            coords => {
                // image to map
                let typedCoords = new OlImageCoordinate(coords[0], coords[1]);
                let result = imageToMap(typedCoords.flip(options.extent[3])).toOl().toArray();
                return result;
            }
        );

        this.setSource(new Static({
            url: options.url,
            projection: this.projection,
            imageExtent: options.extent
        }));

        let this_ = this;
        document.addEventListener('keydown', function (evt) {
            if (evt.key === 'Shift') {
                this_.setOpacity(0.0);
                evt.preventDefault();
            }
        });

        document.addEventListener('keyup', function (evt) {
            if (evt.key === 'Shift') {
                this_.setOpacity(1.0);
            }
        });

        document.addEventListener("touchstart", function(_e) {
            this_.setOpacity(0.0);
        });

        document.addEventListener("touchend", function(_e) {
            this_.setOpacity(1.0);
        });
    }

    public getProjection() {
        return this.projection;
    }

    public setPoints(mapPoints: GpsMapCoordinate[], imagePoints: ImageCoordinate[]): boolean {
        let success = calculate(mapPoints, imagePoints, 0);

        // calculate layer extent to prevent ghost images
        let width = this.options.extent[2];
        let height = this.options.extent[3];
        let corners = [
            // top left
            imageToMap(new ImageCoordinate(0, 0)).toOl(),
            // top right
            imageToMap(new ImageCoordinate(width, 0)).toOl(),
            // bottom left
            imageToMap(new ImageCoordinate(0, height)).toOl(),
            // bottom right
            imageToMap(new ImageCoordinate(width, height)).toOl()
        ];
        let min_x = Math.min(...corners.map(p => p.lon));
        let max_x = Math.max(...corners.map(p => p.lon));
        let min_y = Math.min(...corners.map(p => p.lat));
        let max_y = Math.max(...corners.map(p => p.lat));
        
        let extent = [min_x, min_y, max_x, max_y];
        this.setExtent(extent);

        return success;
    }
}

export  interface GeorefLayerOptions {
    id: string;
    url: string;
    extent: number[];
}

import { Navbar, NavbarItem } from "@joushx/react-components"
import { Outlet, matchPath, useLocation, useNavigate } from "react-router-dom"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function Layout() {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const menu: any[] = [
        {
            title: "Projects",
            path: "/projects"
        }
    ];

    return (
        <>
            <ToastContainer />

            <div className="flex flex-col h-full">
                <Navbar title="GeoRef" onTitleClick={() => navigate("/")}>
                    { menu.map(menuItem => {
                        const isActive = !!matchPath(menuItem.path, pathname)
                        return <NavbarItem title={menuItem.title} onClick={() => navigate(menuItem.path)} active={isActive} key={menuItem.path} />
                    })}
                </Navbar>

                <div className="flex-1 h-full">
                    <Outlet/>
                </div>
            </div>
        </>
    )
}
import Group from "ol/layer/Group";
import Tile from "ol/layer/Tile";
import { transform as olTransform } from "ol/proj";
import { OSM, XYZ,} from "ol/source";
import { GeorefImage } from "./model";
import { Map } from "ol";
import * as protobuf from "protobufjs";
import { v4 } from "uuid";
import { PhotoPosition } from "./services/PhotoPositionService";
import { toast } from "react-toastify";

abstract class AbstractMapCoordinate {
    constructor(
        public lon: number,
        public lat: number
    ) {
    }

    public toArray(): number[] {
        return [this.lon, this.lat]
    }
}

export class GpsMapCoordinate extends AbstractMapCoordinate {
    public toOl(): OlMapCoordinate {
        let newCoordinates = olTransform(
            [this.lon, this.lat],
            "EPSG:4326",
            "EPSG:3857"
        );

        return new OlMapCoordinate(newCoordinates[0], newCoordinates[1]);
    }
}

export class OlMapCoordinate extends AbstractMapCoordinate {
    public toGps(): GpsMapCoordinate {
        let newCoordinates = olTransform(
            [this.lon, this.lat],
            "EPSG:3857",
            "EPSG:4326"
        );

        return new GpsMapCoordinate(newCoordinates[0], newCoordinates[1]);
    }
}

export abstract class AbstractImageCoordinate {
    constructor(
        public x: number,
        public y: number
    ) {}

    public toArray(): number[] {
        return [this.x, this.y]
    }
}

export class ImageCoordinate extends AbstractImageCoordinate {
    public flip(imageHeight: number): OlImageCoordinate {
        return new OlImageCoordinate(this.x, imageHeight - this.y);
    }
}

export class OlImageCoordinate extends AbstractImageCoordinate {
    public flip(imageHeight: number): ImageCoordinate {
        return new ImageCoordinate(this.x, imageHeight - this.y);
    }
}

export function getDefaultLayers(): any[] {
    return [
        new Group({
            // @ts-ignore
            title: "basemap.at",
            fold: "open",
            layers: [
                new Tile({
                    // @ts-ignore
                    title: "Map",
                    type: "base",
                    source: new XYZ({
                        attributions: 'Base map: <a target="_blank" href="https://basemap.at/">basemap.at</a>',
                        url: 'https://mapsneu.wien.gv.at/basemap/geolandbasemap/normal/google3857/{z}/{y}/{x}.png',
                    }),
                }),
                new Tile({
                    // @ts-ignore
                    title: "Orthofoto",
                    type: "base",
                    source: new XYZ({
                        attributions: 'Base map: <a target="_blank" href="https://basemap.at/">basemap.at</a>',
                        url: 'https://mapsneu.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg',
                    }),
                }),
                new Tile({
                    // @ts-ignore
                    title: "Elevation",
                    type: "base",
                    source: new XYZ({
                        attributions: 'Base map: <a target="_blank" href="https://basemap.at/">basemap.at</a>',
                        url: 'https://mapsneu.wien.gv.at/basemap/bmapgelaende/grau/google3857/{z}/{y}/{x}.jpeg',
                    }),
                }),
                new Tile({
                    // @ts-ignore
                    title: "Surface",
                    type: "base",
                    source: new XYZ({
                        attributions: 'Base map: <a target="_blank" href="https://basemap.at/">basemap.at</a>',
                        url: 'https://mapsneu.wien.gv.at/basemap/bmapoberflaeche/grau/google3857/{z}/{y}/{x}.jpeg',
                    }),
                }),
                new Tile({
                    // @ts-ignore
                    title: "Overlay",
                    visible: false,
                    source: new XYZ({
                        attributions: 'Base map: <a target="_blank" href="https://basemap.at/">basemap.at</a>',
                        url: 'https://mapsneu.wien.gv.at/basemap/bmapoverlay/normal/google3857/{z}/{y}/{x}.png',
                    }),
                })
            ]
        }),
        new Tile({
            // @ts-ignore
            title: "OpenStreetMap",
            visible: true,
            type: "base",
            source: new OSM()
        })
    ]
}

export function downloadTextFile(filename: string, text: string) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function generateGdalScript(image: GeorefImage) {

    let gcps = image.points.map(p => {
        return `-gcp ${p.imagePoint[0]} ${p.imagePoint[1]} ${p.mapPoint[0]} ${p.mapPoint[1]}`
    }).join(" ");

    let text = `#!/bin/bash

if [ -z "$1" ]; then
  echo "Usage: $0 <image-file>"
  exit 1
fi

gdal_translate -of GTiff -a_srs EPSG:4326 ${gcps} "$1" /tmp/tmp.tif

gdalwarp -dstalpha -r near -order 1 -overwrite -co TFW=YES /tmp/tmp.tif "$1.tif"
`;

    downloadTextFile("gdal_" + image.id + ".sh", text);
}

export function generateJson(image: GeorefImage) {
    downloadTextFile(image.id + ".json", JSON.stringify(image, null, 2));
}

export function generateKML(image: GeorefImage) {
    PhotoPosition.calculate(image).then(result => {
        console.log(result);

        let ns = "http://www.opengis.net/kml/2.2";

        let doc = document.implementation.createDocument(ns, "", null);

        let kml = document.createElementNS(ns, "kml");
        doc.appendChild(kml);

        let photo_overlay = document.createElementNS(ns, "PhotoOverlay");
        kml.appendChild(photo_overlay);

        let name = document.createElementNS(ns, "name");
        photo_overlay.appendChild(name);
        name.textContent = image.id;

        let camera = document.createElementNS(ns, "Camera");
        photo_overlay.appendChild(camera);

        let lon = document.createElementNS(ns, "longitude");
        camera.appendChild(lon);
        lon.textContent = result.lon.toString();
        
        let lat = document.createElementNS(ns, "latitude");
        camera.appendChild(lat);
        lat.textContent = result.lat.toString();

        let alt = document.createElementNS(ns, "altitude");
        camera.appendChild(alt);
        alt.textContent = result.alt.toString();

        let heading = document.createElementNS(ns, "heading");
        camera.appendChild(heading);
        heading.textContent = result.heading.toString();

        let tilt = document.createElementNS(ns, "tilt");
        camera.appendChild(tilt);
        tilt.textContent = result.tilt.toString();

        let roll = document.createElementNS(ns, "roll");
        camera.appendChild(roll);
        roll.textContent = result.roll.toString();

        let altMode = document.createElementNS(ns, "altitudeMode");
        camera.appendChild(altMode);
        altMode.textContent = "relativeToGround";

        let rotation = document.createElementNS(ns, "rotation");
        photo_overlay.appendChild(rotation);
        rotation.textContent = result.roll.toString();

        let icon = document.createElementNS(ns, "Icon");
        photo_overlay.appendChild(icon);

        let href = document.createElementNS(ns, "href");
        icon.appendChild(href);
        href.textContent = image.url;

        let view_volume = document.createElementNS(ns, "ViewVolume");
        photo_overlay.appendChild(view_volume);

        let left_fov = document.createElementNS(ns, "leftFov");
        view_volume.append(left_fov);
        left_fov.textContent = (result.fov_x/2 * -1).toString();

        let right_fov = document.createElementNS(ns, "rightFov");
        view_volume.append(right_fov);
        right_fov.textContent = (result.fov_x/2).toString();

        let top_fov = document.createElementNS(ns, "topFov");
        view_volume.append(top_fov);
        top_fov.textContent = (result.fov_y/2).toString();

        let bottom_fov = document.createElementNS(ns, "bottomFov");
        view_volume.append(bottom_fov);
        bottom_fov.textContent = (result.fov_y/2 * -1).toString();

        var serializer = new XMLSerializer();
        var xmlString = serializer.serializeToString(doc);

        downloadTextFile(image.id + ".kml", xmlString);
    })
    .catch(e => {
        toast.error("Unable to generate: " + e); 
    })
}

export function getExtent(image: GeorefImage): number[] {
    let minX = 180;
    let maxX = -180;
    let minY = 90;
    let maxY = -90;

    image.points.forEach(point => {
        let [x, y] = point.mapPoint;

        maxX = Math.max(x,  maxX);
        minX = Math.min(x, minX);
        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);
    });

    let min = new GpsMapCoordinate(minX, minY).toOl();
    let max = new GpsMapCoordinate(maxX, maxY).toOl();

    return [min.lon, min.lat, max.lon, max.lat];
}

export function zoomToImage(image: GeorefImage, map: Map) {
    let numberOfPoints = image.points.length;
    if (numberOfPoints === 0) {
        return;
    }

    if(numberOfPoints < 3) {
        let point = image.points[0].mapPoint;
        map.getView().setCenter(
            new GpsMapCoordinate(point[0], point[1]).toOl().toArray(),
        )
        map.getView().setZoom(14);
        return;
    }

    let extent = getExtent(image);
    map.getView().fit(extent);
}

export function generateShareURL(image: GeorefImage) {
    return encodeImageForShare(image).then(data => {
        return `${window.location.protocol}//${window.location.host}/#/share/${data}`
    })
}

export function decodeImageFromShare(data: string): Promise<GeorefImage> {
    return new Promise<GeorefImage>(done => {
        protobuf.load("/image.proto", function(err, root) {
            if (err)
                throw err;

            if(!root) {
                return;
            }

            let buffer = Uint8Array.from(atob(data), c => c.charCodeAt(0))

            var Image = root.lookupType("jmittendorfer.Image");
            let message = Image.decode(buffer);

            let object = Image.toObject(message, {
                longs: String,
                enums: String,
                bytes: String,
            });

            let image: GeorefImage = {
                id: v4(),
                url: object.url,
                extent: [
                    object.extent.minX, 
                    object.extent.minY, 
                    object.extent.maxX,
                    object.extent.maxY
                ],
                points: object.points.map((p: any) => {
                    return {
                        mapPoint: [
                            p.mapX,
                            p.mapY
                        ],
                        imagePoint: [
                            p.imageX,
                            p.imageY
                        ]
                    }
                })
            }

            done(image);
        });
    });
}

async function bufferToBase64(buffer: Uint8Array) {
  const base64url: string = await new Promise(r => {
    const reader = new FileReader()
    // @ts-ignore
    reader.onload = () => r(reader.result)
    reader.readAsDataURL(new Blob([buffer]))
  });
  return base64url.slice(base64url.indexOf(',') + 1);
}

export function encodeImageForShare(image: GeorefImage): Promise<string> {
    return new Promise<string>(done => {
        protobuf.load("/image.proto", function(err, root) {
            if (err)
                throw err;

            if(!root) {
                return;
            }

            let object = {
                url: image.url,
                extent: {
                    minX: image.extent[0],
                    minY: image.extent[1],
                    maxX: image.extent[2],
                    maxY: image.extent[3]
                },
                points: image.points.map(p => {
                    return {
                        mapX: p.mapPoint[0],
                        mapY: p.mapPoint[1],
                        imageX: p.imagePoint[0],
                        imageY: p.imagePoint[1]
                    }
                })
            }

            console.log(object);

            var Image = root.lookupType("jmittendorfer.Image");
            var errMsg = Image.verify(object);
            if (errMsg)
                throw Error("Cannot encode: " + errMsg);
            var message = Image.create(object);
            let buffer = Image.encode(message).finish();

            let b64encoded = bufferToBase64(buffer);

            done(b64encoded);
        });
    });
}

export function generateGCPPointsFile(image: GeorefImage) {
    let points = image.points.map(p => {
        return p.mapPoint[0] + "," + p.mapPoint[1] + "," + p.imagePoint[0] + "," + -p.imagePoint[1] + ",1,0,0,0"
    }).join("\n");

    let result = `#CRS: GEOGCRS["WGS 84",ENSEMBLE["World Geodetic System 1984 ensemble",MEMBER["World Geodetic System 1984 (Transit)"],MEMBER["World Geodetic System 1984 (G730)"],MEMBER["World Geodetic System 1984 (G873)"],MEMBER["World Geodetic System 1984 (G1150)"],MEMBER["World Geodetic System 1984 (G1674)"],MEMBER["World Geodetic System 1984 (G1762)"],MEMBER["World Geodetic System 1984 (G2139)"],MEMBER["World Geodetic System 1984 (G2296)"],ELLIPSOID["WGS 84",6378137,298.257223563,LENGTHUNIT["metre",1]],ENSEMBLEACCURACY[2.0]],PRIMEM["Greenwich",0,ANGLEUNIT["degree",0.0174532925199433]],CS[ellipsoidal,2],AXIS["geodetic latitude (Lat)",north,ORDER[1],ANGLEUNIT["degree",0.0174532925199433]],AXIS["geodetic longitude (Lon)",east,ORDER[2],ANGLEUNIT["degree",0.0174532925199433]],USAGE[SCOPE["Horizontal component of 3D system."],AREA["World."],BBOX[-90,-180,90,180]],ID["EPSG",4326]]
mapX,mapY,sourceX,sourceY,enable,dX,dY,residual
${points}
`;

    downloadTextFile(image.id + ".points", result);
}


import { readFromBinaryFile } from "exif-js";

export class ExifDataService {
    public static getData(url: string): Promise<any> {
        return this.loadImage(url)
        .then(data => readFromBinaryFile(data)); 
    }

    private static loadImage(url: string): Promise<ArrayBuffer> {
        return new Promise((acc, rej) => {
            try {
                var http = new XMLHttpRequest();
                http.onload = function() {
                    if (this.status === 200 || this.status === 0) {
                        acc(http.response);
                    } else {
                        rej("Could not load image");
                    }
                };
                http.onerror = (e) => {rej(e)};
                http.open("GET", url, true);
                http.responseType = "arraybuffer";
                http.send(null);
            }
            catch (e) {
                rej(e);
            }
        })
    }
}
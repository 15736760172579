import {
    useNavigate, useParams,
  } from "react-router-dom";
import { StorageService } from "../services/StorageService";
import { toast } from "react-toastify";
import React from "react";
import { decodeImageFromShare } from "../util";
import { Container } from "../components/Container";
import { v4 } from "uuid";

export function Shared() {
    const params = useParams();
    const navigate = useNavigate();

    React.useEffect(() => {
      decodeImageFromShare(params["*"] as string).then(image => {
        let project = {
          id: v4(),
          name: "Import at " + new Date(),
          images: [
            image
          ]
        };
        StorageService.addProject(project)
        toast.info("Import successful");
        navigate(`/projects/${project.id}/images/${image.id}`);
      });
    }, []);

    return <Container>
      <p>Decoding...</p>
    </Container>
}

import './App.css'

import {
  createHashRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Layout } from './views/Layout';
import "@joushx/react-components/dist/style.css";
import "bootstrap-icons/font/bootstrap-icons.css"
import { Images } from './views/ProjectView';
import { StorageService } from './services/StorageService';
import NewImage from './views/NewImage';
import { ImageDetails } from './views/ImageDetails';
import { SplitEditor } from './views/SplitEditor';
import { GeorefViewer } from './views/GeorefViewer';
import React, { useState } from 'react';
import { initGdal } from './services/GdalService';
import { Loading } from './views/Loading';
import { OverlayEditor } from './views/OverlayEditor';
import { Shared } from './views/Shared';
import { CesiumViewer } from './views/CesiumViewer';
import { ProjectsView } from './views/ProjectsView';
import { ImageLayout } from './views/ImageLayout';
import { CombinedViewer } from './views/CombinedViewer';
import { CesiumCombinedViewer } from './views/CesiumCombinedViewer';

const imageLoader = async ({ params }: any) => {
  return StorageService.getImage(params.project_id, params.image_id);
};

export default function App() {
  const [gdalLoaded, setGdalLoaded] = useState<boolean>(false);

  React.useEffect(() => {
    initGdal().then(() => {
      setGdalLoaded(true);
    }).catch(e => {
      console.error(e);
    })
  }, []);

  const router = createHashRouter([
    {
      path: "/",
      element: <Layout/>,
      children: [
        {
          index: true,
          element: <Navigate to="/projects"/>
        },
        {
          path: "projects",
          children: [
            {
              index: true,
              element: <ProjectsView/>,
              loader: async () => {
                return StorageService.getProjects();
              }
            },
            {
              path: ":project_id",
              children: [
                {
                  index: true,
                  loader: async({ params }) => StorageService.getProject(params.project_id as string),
                  element: <Images/>
                },
                {
                  path: "map",
                  loader: async({ params }) => StorageService.getProject(params.project_id as string)?.images,
                  element: <CombinedViewer/>
                },
                {
                  path: "3d",
                  loader: async({ params }) => StorageService.getProject(params.project_id as string)?.images,
                  element: <CesiumCombinedViewer/>
                },
                {
                  path: "images",
                  children: [
                    {
                      path: "new",
                      element: <NewImage/>
                    },
                    {
                      path: ":image_id",
                      element: <ImageLayout/>,
                      loader: imageLoader,
                      children: [
                        {
                          index: true,
                          loader: imageLoader,
                          element: <ImageDetails/>,
                        },
                        {
                          path: "view",
                          loader: imageLoader,
                          element: <GeorefViewer/>
                        },
                        {
                          path: "3d",
                          loader: imageLoader,
                          element: <CesiumViewer/>
                        },
                        {
                          path: ":edit/split",
                          loader: imageLoader,
                          element: <SplitEditor/>
                        },
                        {
                          path: ":edit/overlay",
                          loader: imageLoader,
                          element: <OverlayEditor/>
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: "share/*",
          element: <Shared/>
        }
      ]
    }
  ]);

  if(!gdalLoaded) {
    return <Loading/>
  }

  return <RouterProvider router={router}/>
}
export class Log {
    
    public static debug(tag: string, message: string) {
        this.log(tag, message, "DEBUG", console.debug);
    }

    public static warn(tag: string, message: string) {
        this.log(tag, message, "WARN", console.warn);
    }

    public static info(tag: string, message: string) {
        this.log(tag, message, "INFO", console.info);
    }

    public static error(tag: string, message: string) {
        this.log(tag, message, "ERROR", console.error);
    }

    private static log(tag: string, message: string, level: string, method: any) {
        method(`${level}\t${tag}\t${message}`);
    }
}
import { GpsMapCoordinate, ImageCoordinate } from "../util";
import loadGdal from "../wasm-interfaces/gdal";

let buffer: number;
let update: (length: number, order: number, points: number[]) => number;
let transformCoords: (reverse: number, buffer: number) => number;
let gdalInstance: GdalModule|null = null;

export function initGdal(): Promise<void> {
  return loadGdal().then((gdal: GdalModule) => {

    // allocate buffer to use for point array
    buffer = gdal._malloc(2 * 64);

    // get handle to methods
    update = gdal.cwrap("update", "number", ['number', 'number', 'array']);
    transformCoords = gdal.cwrap("transform", "number", ["number", "number"])
    
    gdalInstance = gdal;
  });
}

export function mapToImage(point: GpsMapCoordinate): ImageCoordinate {
  let result = transform([point.lon, point.lat], true);
  return new ImageCoordinate(result[0], result[1]);
};

export function imageToMap(point: ImageCoordinate): GpsMapCoordinate {
  let result = transform([point.x, point.y], false);
  return new GpsMapCoordinate(result[0], result[1]);
};

function transform(coordinates: number[], reverse: boolean): Float64Array {
  if(!gdalInstance) {
    throw new Error("GDAL module is not initialized");
  }

  let inputArray = new Float64Array(coordinates);
  gdalInstance.HEAPF64.set(inputArray, buffer / 8); // find offset in 64 bit steps from byte offset
  transformCoords(reverse ? 1 : 0, buffer);
  return new Float64Array(gdalInstance.HEAPF64.buffer, buffer, 2);  
}

export function calculate(mapPoints: GpsMapCoordinate[], imagePoints: ImageCoordinate[], order: number): boolean {
  if (mapPoints.length !== imagePoints.length)
    throw new Error("Number of points do not mach!");

  // serialize to single array, because we cannot pass 
  // multidimensional arrays over to wasm
  let combined = mapPoints.map((_v,i) => {
    return [
      mapPoints[i].lon,
      mapPoints[i].lat,
      imagePoints[i].x,
      imagePoints[i].y,
    ];
  }).flat();

  // @ts-ignore
  let wasmResult = update(mapPoints.length, order, new Uint8Array(new Float64Array(combined).buffer));
  return wasmResult === 1;
};

export interface GdalModule {
  cwrap(name: string, return_type: string, arg_types: string[]): (...args: any) => any;
  _malloc(size: number): number;
  HEAPF64: any;
}

import React from "react";
import { View } from "ol";
import Map from 'ol/Map.js';
import { useLoaderData, useParams } from 'react-router-dom';
import LayerSwitcher from "ol-layerswitcher";
import { imageToMap, mapToImage } from "../services/GdalService";
import { GeorefLayer } from "../components/GeorefLayer";
import { PointsLayer } from "../components/PointsLayer";
import { GeorefImage } from "../model";
import { OlMapCoordinate, GpsMapCoordinate, ImageCoordinate, getDefaultLayers, zoomToImage } from "../util";
import { StorageService } from "../services/StorageService";
import { toast } from "react-toastify";

export function OverlayEditor() {
    const mapDiv = React.useRef(null);
    let imageSelection: OlMapCoordinate | null = null;
    const image = useLoaderData() as GeorefImage;
    const params = useParams();

    React.useEffect(() => {
        initMap();
    }, []);

    function initMap() {

        if(!mapDiv.current) {
            return;
        }

        let map = new Map({
            layers: getDefaultLayers(),
            target: mapDiv.current,
            view: new View({
              projection: "EPSG:3857",
              center: new GpsMapCoordinate(0, 0).toOl().toArray(),
              zoom: 1
            })
        });

        zoomToImage(image, map);

        let georefLayer = new GeorefLayer({
            id: image.id,
            url: image.url,
            extent: image.extent
        });

        let mapPoints = image.points.map(c => new GpsMapCoordinate(c.mapPoint[0], c.mapPoint[1]));
        let imagePoints = image.points.map(c => new ImageCoordinate(c.imagePoint[0], c.imagePoint[1]));
        let success = georefLayer.setPoints(mapPoints, imagePoints);
        if(!success) {
            toast.error("Not enough points to find solution");
            return;
        }

        // add points layer
        let mapPointsLayer = new PointsLayer(mapPoints.map(p => p.toOl().toArray()));
        mapPointsLayer.setOpacity(0.0);

        let imagePointsLayer = new PointsLayer(imagePoints.map(p => {
            let typedC: ImageCoordinate = new ImageCoordinate(p.x, p.y);
            return imageToMap(typedC).toOl().toArray();
        }));

        map.addLayer(mapPointsLayer);
        map.addLayer(georefLayer);
        map.addLayer(imagePointsLayer);

        var layerSwitcher = new LayerSwitcher({
            tipLabel: 'Legende',
            groupSelectStyle: 'children'
        });
        map.addControl(layerSwitcher);

        map.on("click", e => {
            let c = map.getCoordinateFromPixel(e.pixel);
            if (imageSelection == null) {
                let typedC = new OlMapCoordinate(c[0], c[1]);
                imagePointsLayer.setOpacity(0.0);
                georefLayer.setOpacity(0.0);
                mapPointsLayer.setOpacity(1.0);
                imageSelection = typedC;
            } else {
                let typedC = new OlMapCoordinate(c[0], c[1]);
                mapPointsLayer.setOpacity(0.0);
                imagePointsLayer.setOpacity(1.0);
                georefLayer.setOpacity(1.0);

                let result = mapToImage(imageSelection.toGps());
                image.points.push({
                    mapPoint: typedC.toGps().toArray(),
                    imagePoint: result.toArray()
                });

                StorageService.updateImage(params.project_id as string, image);

                let mapPoints = image.points.map(c => new GpsMapCoordinate(c.mapPoint[0], c.mapPoint[1]));
                let imagePoints = image.points.map(c => new ImageCoordinate(c.imagePoint[0], c.imagePoint[1]));
                georefLayer.setPoints(mapPoints, imagePoints);

                imagePointsLayer.addPoint(result.toArray());
                mapPointsLayer.addPoint(typedC.toArray());
                imageSelection = null;
            }
        });

        document.addEventListener('keydown', function (evt) {
            if (evt.key === 'Shift') {
                imagePointsLayer.setOpacity(0.0);
                mapPointsLayer.setOpacity(1.0);
            }
        });

        document.addEventListener('keyup', function (evt) {
            if (evt.key === 'Shift') {
                imagePointsLayer.setOpacity(1.0);
                mapPointsLayer.setOpacity(0.0);
            }
        });
    }

    return <div className="h-full" ref={mapDiv}>
    </div>
}

import { useEffect } from 'react'
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import LayerSwitcher, {  } from 'ol-layerswitcher';
import { GeorefLayer } from '../components/GeorefLayer';
import { GpsMapCoordinate, ImageCoordinate, getDefaultLayers, zoomToImage } from '../util';
import { GeorefImage } from '../model';
import { toast } from 'react-toastify';
import "ol-layerswitcher/dist/ol-layerswitcher.css";

export function GeorefViewer() {
    const mapDiv = React.useRef(null);
    const image = useLoaderData() as GeorefImage;

    useEffect(() => {
        if (!mapDiv.current) {
            toast.error("Cannot find map");
            return;
        }

        let mainMap = new Map({
            layers: getDefaultLayers(),
            target: mapDiv.current,
            view: new View({
                projection: "EPSG:3857",
                center: new GpsMapCoordinate(0, 0).toOl().toArray(),
                zoom: 14
            })
        });

        let mapPoints = image.points.map(c => new GpsMapCoordinate(c.mapPoint[0], c.mapPoint[1]));
        let imagePoints = image.points.map(c => new ImageCoordinate(c.imagePoint[0], c.imagePoint[1]));

        let georefLayer = new GeorefLayer({
            id: image.id,
            url: image.url, 
            extent:image.extent
        });
        mainMap.addLayer(georefLayer);

        var layerSwitcher = new LayerSwitcher({
            tipLabel: 'Legende', // Optional label for button
            groupSelectStyle: 'children' // Can be 'children' [default], 'group' or 'none'
        });
        mainMap.addControl(layerSwitcher);

        zoomToImage(image, mainMap);

        let success = georefLayer.setPoints(mapPoints, imagePoints);
        if(!success) {
            toast.error("Not enough points to find solution");
        }
    }, [image]);

    return (
        <div className="h-full" ref={mapDiv}></div>
    )
}